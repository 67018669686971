import React from 'react'
import Hero from '../components/Hero'
import LuxuryAdvert from '../components/LuxuryAdvert.jsx'

const Home = () => {
  return (
    <div>
      <Hero/>
      <LuxuryAdvert/>
    </div>
  )
}

export default Home
