import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import Navbar from './components/nav/Navbar';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/nav/Footer';
import Home from './pages/Home';
import ScrollToTop from './components/ScrollToTop';
import { MdKeyboardArrowUp } from "react-icons/md";
import "./App.css"
import ExploreNow from './components/ExploreNow';
import { FaWhatsapp, FaWhatsappSquare } from 'react-icons/fa';
import CookieConsent from './components/CookieConsent';


function App() {
  const [showScrollButton, setShowScrollButton] = useState(false); 

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll to top functionality
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className='app'>
    
      <Router>

        <ScrollToTop/>
        <CookieConsent />

        { /* Scroll to Top Button */}
        {showScrollButton && (
         <button className="scroll-to-top" onClick={scrollToTop}>
           <MdKeyboardArrowUp />
         </button>
        )}  

        <Link to={"https://wa.me/2348142471208"} className='watsap'>
          <FaWhatsappSquare/>
        </Link>

      <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/explorenow" element={<ExploreNow />} />
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
