import React from 'react';
import './About.css';

const About = () => {
  return (
    <section className="about-us" id="about">
      <div className="about-us-container">
        <div className="about-us-text">
          <h2>About Us</h2>
          <p>
            ZEAVIX is your trusted partner for short stays, events, and more. We specialize in providing top-tier event planning, venue rentals, and accommodations for both business and leisure. Our dedicated team ensures every experience is memorable, seamless, and tailored to your needs.
          </p>
          <p>
            From exquisite venues to exceptional catering, ZEAVIX is committed to making every occasion special. Our luxury short-stay accommodations offer the perfect blend of comfort and style, making us the top choice for discerning clients.
          </p>
        </div>
        <div className="about-us-image">
          <img src="/images/bg5.png" alt="About Us" />
        </div>
      </div>
    </section>
  );
};

export default About;
