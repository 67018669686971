import React from 'react';
import './LuxuryAdvert.css';

const LuxuryAdvert = () => {
  return (
    <div className="luxury-advert-container">
      {/* First Advert */}
      <div className="advert-section">
        <div className="advert-image">
          <img src="/images/bg2.png" alt="Luxury" />
        </div>
        <div className="advert-content">
          <h2 className="advert-title">Experience Unmatched Luxury</h2>
          <p className="advert-text">
            Immerse yourself in a world of sophistication and elegance. Our exclusive destinations provide the perfect blend of comfort and style. Explore top-tier services, breathtaking views, and unique experiences that define luxury living. Discover what it means to be truly pampered in our world of opulence.
          </p>
        </div>
      </div>

      {/* Second Advert (Reversed Layout) */}
      <div className="advert-section advert-reverse">
        <div className="advert-content">
          <h2 className="advert-title">Discover Premium Locations</h2>
          <p className="advert-text">
            Find the finest locations tailored to meet your highest expectations. Each of our spots offers something special, from scenic landscapes to exclusive amenities. We redefine luxury by ensuring every detail is perfect, making your stay nothing less than extraordinary.
          </p>
        </div>
        <div className="advert-image">
          <img src="/images/bg1.png" alt="Luxury" />
        </div>
      </div>

      {/* Third Advert */}
      <div className="advert-section">
        <div className="advert-image">
          <img src="/images/bg4.png" alt="Luxury" />
        </div>
        <div className="advert-content">
          <h2 className="advert-title">A World Beyond Imagination</h2>
          <p className="advert-text">
            Step into a world where your dreams become reality. Our luxury services are designed to provide unforgettable moments and unparalleled comfort. Whether it's a grand event or a short stay, our premium offerings are tailored to provide you with a perfect experience.
          </p>
        </div>
      </div>

       {/* fourt Advert (Reversed Layout) */}
       <div className="advert-section advert-reverse">
        <div className="advert-content">
          <h2 className="advert-title">Indulge in Premium Comfort</h2>
          <p className="advert-text">
            Step into a world of elegance and exclusivity, where every location is carefully curated to exceed your expectations. Our destinations blend stunning views with impeccable services, providing a sanctuary of luxury tailored just for you. Every moment is designed to inspire, making your experience not just memorable, but truly unforgettable.
         </p>
        </div>
        <div className="advert-image">
          <img src="/images/bg6.png" alt="Luxury" />
        </div>
      </div>
    </div>
  );
};

export default LuxuryAdvert;
