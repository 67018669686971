import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h2>About ZEAVIX</h2>
          <p>
            ZEAVIX is your ultimate destination for short stays, events, and more. 
            Discover premium locations, top-notch events, and a seamless experience.
          </p>
        </div>
        <div className="footer-section links">
          <h2>Quick Links</h2>
          <ul>
          <li><Link to="/" className="footer-link">Home</Link></li>
          <li><Link to="/about" className="footer-link">About</Link></li>
           <li><Link to="/services" className="footer-link">Services</Link></li>
          <li><Link to="/events" className="footer-link">Events</Link></li>
           <li><Link to="/contact" className="footer-link">Contact</Link></li>

          </ul>
        </div>
        <div className="footer-section cont">
          <h2>Contact Us</h2>
          <p>Email: zeavixxeventandmore@gmail.com</p>
          <p>Phone: +234 814 247 1208</p>
          {/* Social Media Links with Icons */}
          
            <div className="footer-social-links">
              <a href="https://www.facebook.com/profile.php?id=61569412164029&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={20} />
              </a>
              <a href="https://www.twitter.com/zeavix" target="_blank" rel="noopener noreferrer">
                <FaTwitter size={20} />
              </a>
              <a href="https://www.instagram.com/zeavixeventandmore/profilecard/?igsh=YmxydG1wZmg0bXdu" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={20} />
              </a>
              <a href="https://www.linkedin.com/zeavix" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={20} />
              </a>
            </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} ZEAVIX. All Rights Reserved. <Link to={"https://www.oguntoyinbo.com.ng"} style={{ color:"rgb(11, 206, 206)"}}>Multibrand</Link></p>
      </div>
    </footer>
  );
};

export default Footer;
