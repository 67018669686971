import React from 'react';
import './Services.css';

const servicesData = [
  {
    title: 'Event Planning',
    description: 'Professional event planning for all occasions, from weddings to corporate events.',
    icon: '🎉'
  },
  {
    title: 'Short Stays',
    description: 'Luxury short-stay accommodations for business or leisure.',
    icon: '🏨'
  },
  {
    title: 'Venue Rentals',
    description: 'Book premium venues for your events, meetings, and more.',
    icon: '🏢'
  },
  {
    title: 'Catering Services',
    description: 'Top-notch catering services for all types of events.',
    icon: '🍽️'
  }
];

const Services = () => {
  return (
    <section className="services" id="services">
      <h2>Our Services</h2>
      <div className="services-container">
        {servicesData.map((service, index) => (
          <div className="service-card" key={index}>
            <div className="service-icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
